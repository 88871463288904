import React from 'react';
import './HowToPlay.css';
import Clouds from '../../images/Clouds.svg';
import Big1 from '../../images/HowTo/Big1.svg';
import Big2 from '../../images/HowTo/Big2.svg';
import Big3 from '../../images/HowTo/Big3.svg';
import Big4 from '../../images/HowTo/Big4.svg';
import Step1Image from '../../images/HowTo/Step1-image.svg';
import Step2Image from '../../images/HowTo/Step2-image.svg';
import Step3Image from '../../images/HowTo/Step3-image.svg';
import Step4Image from '../../images/HowTo/Step4-image.svg';

const HowToPlay = () => (
  <section className="how-to-play">
    <div className="title-wrapper">
      <h1>How To Roast</h1>
      <div className="how-to-cloud-wrapper">
        <img src={Clouds} className="how-to-play-cloud" alt="Fluffy cloud" />

      </div>
    </div>


    <article className="how-to-step">
      <div className="number-and-text">
        <div className="how-to-number-wrapper">
          <img src={Big1} className="big-number number-one" alt="Large number 1" />
        </div>

        <div className="how-to-text">
          <h2>Create a Game</h2>
          <p>Start a game by choosing 4 decks</p>
          <img src={Step1Image} className="mobile-only-step-image" alt="4 card categories" />
          <p>Then record the names of the friends playing, then decide if you'll be shouting or writing</p>
        </div>
      </div>

      <div className="how-to-image-wrapper">
        <img src={Step1Image} className="how-to-image" alt="4 card categories" />
      </div>
    </article>


    <article className="how-to-step">
      <div className="how-to-image-wrapper">
        <img src={Step2Image} className="how-to-image" alt="Fancy captain's hat" />
      </div>

      <div className="number-and-text">
        <div className="how-to-number-wrapper  even-numbered-step">
          <img src={Big2} className="big-number" alt="Large number 2" />
        </div>

        <div className="how-to-text">
          <h2>Who is the Captain now?</h2>
          <p>A captain is randomly chosen each round</p>
          <img src={Step2Image} className="mobile-only-step-image" alt="Fancy captain's hat" />
          <p>Then they select a deck and statement to be roasted on. For example: "Michael's parents are disappointed in him ever since he _______".</p>
        </div>
      </div>
    </article>


    <article className="how-to-step">
      <div className="number-and-text">
        <div className="how-to-number-wrapper">
          <img src={Big3} className="big-number" alt="Large number 3" />
        </div>

        <div className="how-to-text">
          <h2>Time to Roast</h2>
          <p>All players roast the captain, either shouting or writing depending on the game mode </p>
          <img src={Step3Image} className="mobile-only-step-image" alt="Casting your vote" />
          <p>The captain can give as many points as they want during shouting mode, but only 1 point per round during writing mode</p>
        </div>
      </div>

      <div className="how-to-image-wrapper">
        <img src={Step3Image} className="how-to-image" alt="Casting your vote" />
      </div>
    </article>


    <article className="how-to-step">
      <div className="how-to-image-wrapper">
        <img src={Step4Image} className="how-to-image" alt="Treasure Chest" />
      </div>

      <div className="number-and-text">
        <div className="how-to-number-wrapper even-numbered-step">
          <img src={Big4} className="big-number" alt="Large number 4" />
        </div>

        <div className="how-to-text">
          <h2>Earn Points <br/> to Win</h2>
          <img src={Step4Image} className="mobile-only-step-image" alt="Treasure Chest" />
          <p>The player with the most points at the end of the game wins.</p>
        </div>
      </div>
    </article>

  </section>
);

export default HowToPlay;
