import React from 'react';
import './PAQ.css';
import PAQTop from '../../images/PAQ/PAQ-top.svg';
import Bird from '../../images/PAQ/Bird.svg';
import Skull from '../../images/PAQ/Skull.svg';

const questionsAnswers = [
  {
    question: "What is Endship?",
    answer: "Endship is a funny game where friends roast each other. Games usually last 15-45 minutes. Sometimes it's not funny.",
  },
  {
    question: "How much does it cost?",
    answer: "Endship is free with categories like Jobs and Party Time, but you can make it not free by purchasing premium categories like Politics, Dating, and Videogames.",
  },
  {
    question: "How do you roast your friends?",
    answer: "Carefully. Start by listening to them, and try to twist what they say into something silly, taboo, criminal, ridiculous, or sweet. The goal should always be to make them laugh. If what you said looks like it wounded them, speak with them privately afterwards. This may involve listening to them a second time, but whatever you do, don't contact us about it.",
  },
  {
    question: "Why is this section called potentially asked questions instead of frequently?",
    answer: "We're not yet popular enough to have frequent questions.",
  },
  {
    question: "What if this app actually ends my friendship with someone?",
    answer: "You're welcome.",
  },
]

const PAQ = () => {
  const onPress = (e: any) => {
    console.log("console says:", e)
    // know which element was pressed
  }

  return (
  <section className="PAQ-section">
    <div className="birds-wrapper">
      <div className="birds-rotation">
        <div className="birds-resizing">
          <img src={Bird} className="bird1" alt="Bird" />
          <img src={Bird} className="bird2" alt="Bird" />
          <img src={Bird} className="bird3" alt="Bird" />
        </div>
      </div>
    </div>
    <img src={PAQTop} className="PAQ-top" alt="Beach with trees" />


    <div className="PAQ-content">
      <div className="white-box">
        <h2 className="PAQ-title">Potentially Asked Questions</h2>


        <ul>
        {questionsAnswers.map((data, i) => {
          return (
            // @ts-ignore
            <li onPress={onPress}>
              <div className="PAQ-question">
                <img src={Skull} className="skull" alt="Skull" />
                <h3>{data.question}</h3>
              </div>
              <details className="PAQ-answer">
                <summary></summary>
                {data.answer}
              </details>
            </li>
          )
        })}
        </ul>

      </div>
    </div>
  </section>
)};

export default PAQ;
