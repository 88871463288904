import React from 'react';
import './Footer.css';
import MapleLeaf from '../../images/Footer/MapleLeaf.svg';
import Mark from '../../images/mark.svg';

const year = new Date().getUTCFullYear();

const Footer = () => (
  <section className="footer">
    <div className="footer-bold-text-and-logo">
      <ul>
        <li><a href="/terms">Terms of use</a></li>
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="https://www.instagram.com/endshipgame">Instagram</a></li>
        <li><a href="https://www.twitter.com/endshipgame">Twitter</a></li>
      </ul>

      <div className="footer-logo-wrapper">
        <img src={Mark} className="footer-logo-mark" alt="Endship Logo" />
        <h2>Endship</h2>
      </div>
    </div>

    <div className="footer-bottom">
      <p className="footer-made-with-text">
        Made with{" "}
        <img src={MapleLeaf} className="maple-leaf" alt="Maple Leaf" />
        {" "}in Amsterdam, Austin, Chiang Mai, Montreal, San Francisco, Seoul, Tel Aviv, Toronto, Vancouver, Winnipeg, and Zagreb.
      </p>
      <div className="footer-bottom-right-text">
        <p>Endship™ is a trademark of <a href="https://www.spotlightly.com">Spotlightly Inc</a>.</p>
        <p>© {year}. All rights reserved.</p>
      </div>
    </div>
  </section>
)

export default Footer;
