import React from 'react';
import './Hero.css';
// import Logo from '../../logo.svg';
import ShipWithCaptain from '../../images/Hero/Ship-with-captain.svg';
import Waves from '../../images/Hero/waves.svg';
import Clouds from '../../images/Clouds.svg';
import HeroWaterline from '../../images/Hero/Hero-waterline.svg';
import HeroBottom from '../../images/Hero/Hero-bottom.svg';
import GooglePlay from '../../images/Hero/GooglePlay.svg';
import AppStore from '../../images/Hero/AppStore.svg';
import Mark from '../../images/mark.svg';

const Hero = () => (
  <section className="hero">
    <div className="Hero-BG-wrapper">
      <div className="hero-top">
        <div className="hero-top-left">
          <img src={Mark} className="hero-logo" alt="Endship Logo" />
          <h1>Endship</h1>
        </div>

        <aside className="hero-top-right">
          <div className="hero-phone-shape">
            <img src={Clouds} className="hero-phone-cloud" alt="Fluffy cloud" />
            <div className="mobile-only-logo-wrapper">
              <img src={Mark} className="mobile-only-logo" alt="Endship Logo" />
              <h2>Endship</h2>
              <p className="phone-copy">Get the party game where friends roast each other!</p>

            </div>
            <p className="phone-copy discovery-text desktop-only">Get the party game where friends roast each other!</p>
            <div className="desktop-buttons desktop-only">
                <a href="https://apps.apple.com/us/app/endship/id1098049379?ls=1" className="badge-wrapper">
                  <img src={AppStore} className="App-store-badge" alt="Apple App Store" />
                </a>
                <br />
                <a href="https://play.google.com/store/apps/details?id=com.endship.spotlightly" className="badge-wrapper">
                  <img src={GooglePlay} className="App-store-badge" alt="Google Play Store" />
                </a>
              </div>

            <div className="hero-phone-sky" />
            <div className="hero-phone-bottom">
              <div className="app-store-buttons mobile-only">
                <a href="https://play.google.com/store/apps/details?id=com.endship.spotlightly" className="badge-wrapper">
                  <img src={GooglePlay} className="App-store-badge" alt="Google Play Store" />
                </a>
                <a href="https://apps.apple.com/us/app/endship/id1098049379?ls=1" className="badge-wrapper">
                  <img src={AppStore} className="App-store-badge" alt="Apple App Store" />
                </a>
              </div>
            </div>
          </div>
        </aside>
      </div>

      <div>
        <img src={HeroWaterline} className="hero-water-surface" alt="Hero Waterline" />
        <div className="hero-water-main">
          <div className="drifting-horizontally">
            <img src={ShipWithCaptain} className="ship-with-captain" alt="Ship with Captain looking through a telescope out in the distance" />
          </div>
          <div className="wave-wrapper">
            <img src={Waves} className="water-in-front-of-ship" alt="Ocean waves" />
          </div>
        </div>
        <img src={HeroBottom} className="hero-bottom" alt="Hero Bottom" />
      </div>

    </div>

  </section>
);

export default Hero;
