import React from 'react';
import './App.css';
import Hero from "./sections/Hero";
import HowToPlay from "./sections/HowToPlay";
import PAQ from "./sections/PAQ";
import Footer from "./sections/Footer";

const App = () => (
  <main>

    <Hero />
    <HowToPlay />
    <PAQ />
    <Footer />

  </main>
)

export default App;
